import React from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import { BsInstagram } from "react-icons/bs";
import { FaFacebookF} from "react-icons/fa";
import { FaXTwitter} from "react-icons/fa6";
import { MdCall, MdMailOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import geslopd from "../assets/geslopd.png";
import FooterLogo from "../assets/Footer.jsx";

const Footer = () => {
  return (
    <footer className="footer mt-10 pt-4 pb-2">
      <div className="canvas">
        <div className="d-flex justify-content-between flex-wrap">
          <div className="footer-col">
            {<FooterLogo />}
            <div className="d-flex mt-3">
              <a
                href="https://instagram.com/postalven"
                target="_blank"
                className="social_icon"
              >
                <BsInstagram />
              </a>
              <a
                href="https://facebook.com/postalven"
                target="_blank"
                className="social_icon ms-2"
              >
                <FaFacebookF />
              </a>
              <a
                href="https://twitter.com/postalven"
                target="_blank"
                className="social_icon ms-2"
              >
                <FaXTwitter />
              </a>
            </div>
            <div className="mt-3">
              <MdMailOutline size={20} color="#fc364e" />
              <Link to={"/contact"} className="text-white ms-3" style={{textDecoration: 'none'}}>
              info.es@postalven.com
              </Link>
            </div>
            <div className="mt-2 d-flex align-items-center">
              <AiOutlineClockCircle size={20} color="#fc364e" />
              <p className="text-white ms-3">L a V de 10 a 18 Hrs.</p>
            </div>
            <div className="mt-2 d-flex align-items-center">
              <MdCall size={20} color="#fc364e" />
              <p className="text-white ms-3">+34 91 993 12 13</p>
            </div>
          </div>
          <div className="footer-col">
            <h2>Ayuda</h2>
            <div className="mt-3">
              <a
                href="https://wa.me/34611591919?text=Hola,%20estamos%20para%20ayudarte%20con%20todas%20tus%20consultas.%20%C2%A1Contin%C3%BAe%20chateando!"
                className="text-white"
                target="_blank"
                style={{textDecoration: 'none'}}
              >
                Chat online
              </a>
            </div>
            <div className="mt-2">
              <Link to={"/contact"} className="text-white" style={{textDecoration: 'none'}}>
                Contacto
              </Link>
            </div>
            <div className="mt-2">
              <Link to={"/terms"} className="text-white" style={{textDecoration: 'none'}}>
                Términos y Condiciones
              </Link>
            </div>
            <div className="mt-2">
              <a href="/dat.pdf" download="DOCUMENTO DE AUTORIZACIÓN A TERCERO (DAT)" className="text-white"
              style={{textDecoration: 'none'}}>Descargar DAT</a>
            </div>
          </div>

          <div className="footer-col">
            <h2>Información</h2>
            <div className="mt-2">
              <Link to={"/privacy"} className="text-white" style={{textDecoration: 'none'}}>
                Política de Privacidad
              </Link>
            </div>
            <div className="mt-2">
              <Link to={"/avisolegal"} className="text-white" style={{textDecoration: 'none'}}>
                Aviso Legal
              </Link>
            </div>
            <div className="mt-2">
              <Link to={"/cookies"} className="text-white" style={{textDecoration: 'none'}}>
                Política de Cookies
              </Link>
            </div>

            <div className="mt-2">
              <Link to={"/protection"} className="text-white" style={{textDecoration: 'none'}}>
                Protección de Datos
              </Link>
            </div>
          </div>

          <div className="footer-col">
            <img src={geslopd} height={170} width={170} />
          </div>
        </div>
      </div>
      <p className="mt-3 px-3 small text-center">
        Copyright &copy; 2025 Euro Digital Multiservices SLU - Imágenes de
        www.freepik.com
      </p>
    </footer>
  );
};

export default Footer;
